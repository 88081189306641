import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import {
	DeviceConnectState,
	OrderState,
	QueryClient,
	StoreInfoState,
	authState,
} from '@kinderlabs-pos/state';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { IconButton, Stack, Typography } from '@mui/material';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, matchPath, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PosMainFallbackComponent } from '../app/PosMainFallbackComponent';
import { PosHeader } from '../components/PosHeader';
import { WaitingIndicator } from '../components/PosHeader/WaitingIndicator';
import { WithBarcodeScanner } from '../components/notistacks/WithBarcodeScanner';
import { PosSSEEventProcessorProvider } from '../composites/PosSSEEventProcessorProvider';
import { mainRoutes, usePosRouter } from './routes';

type CanLeaveFunctionType = () => Promise<boolean>;
type MainTemplateOutletContext = {
	setCanLeave: React.Dispatch<
		React.SetStateAction<{
			canLeave: CanLeaveFunctionType;
		}>
	>;
};
const useCanLeave = (callback: CanLeaveFunctionType) => {
	const { setCanLeave } = useOutletContext<MainTemplateOutletContext>();
	React.useEffect(() => {
		setCanLeave({
			canLeave: callback,
		});
		return () => setCanLeave({ canLeave: async () => true });
	}, [callback]);
};

export const MainPageTemplate = ({}) => {
	const curStoreAndDevice = useAtomValue(StoreInfoState.curStoreAndDevice);

	if (!curStoreAndDevice) return null;

	const { reset } = useQueryErrorResetBoundary();
	const { navigatePosMain } = usePosRouter();

	return (
		<ErrorBoundary
			FallbackComponent={PosMainFallbackComponent}
			onReset={() => {
				// 메인화면으로
				navigatePosMain();
				reset();
				QueryClient.origin.resetQueries();
			}}>
			<Internal
				enterpriseId={curStoreAndDevice.enterpriseId}
				storeId={curStoreAndDevice.storeId}
				posId={curStoreAndDevice.deviceId}
			/>
		</ErrorBoundary>
	);
};

const Internal = ({
	enterpriseId,
	storeId,
	posId,
}: {
	enterpriseId: string;
	storeId: number;
	posId: number;
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [{ canLeave }, setCanLeave] = React.useState<{ canLeave: CanLeaveFunctionType }>({
		canLeave: async () => true,
	});

	const matchedRoute = mainRoutes.find((route) => {
		return matchPath({ path: `/main/${route?.path}` }, pathname);
	});

	const setCalled지류티켓 = useSetAtom(WaitingIndicator.called지류티켓Atom);
	const cartReducer = OrderState.cart.useDispatcher();
	const dispatchOrder = useSetAtom(OrderState.value);

	useEffect(() => {
		return () => {
			setCalled지류티켓(undefined);
			cartReducer({ type: 'CLEAR' });
			dispatchOrder({ type: 'SET_GUEST_USERS_INFO', guestMemberInfo: undefined });
		};
	}, []);

	return (
		<Stack sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}>
			<WithBarcodeScanner>
				<LogoutWhen24 />
				<PosSSEEventProcessorProvider />
				<DeviceConnectState.Connector
					deviceId={posId}
					storeId={storeId}
					deviceType={'POS'}
				/>
				<PosHeader
					canLeave={canLeave}
					disabled={matchedRoute?.noHeader}
				/>
				{matchedRoute && matchedRoute.title && (
					<Stack
						p={1}
						pb={0}
						spacing={1}
						alignItems={'center'}
						direction={'row'}>
						<IconButton
							onClick={async () => {
								if (await canLeave()) navigate(-1);
							}}>
							<ArrowLeftOutlined style={{ fontSize: 18 }} />
						</IconButton>
						<Typography
							sx={{ flex: 1 }}
							variant={'h3'}>
							{matchedRoute.title}
						</Typography>
						<IconButton
							onClick={async () => {
								if (await canLeave()) navigate('/main');
							}}>
							<HomeOutlined style={{ fontSize: 18 }} />
						</IconButton>
					</Stack>
				)}
				<Stack
					p={1}
					flex={1}
					sx={{ overflowY: 'auto' }}>
					<Outlet context={{ setCanLeave }} />
				</Stack>
			</WithBarcodeScanner>
		</Stack>
	);
};

/**
 * 정각 24시에 로그아웃
 */
const LogoutWhen24 = () => {
	const now = dayjs();
	const user = useAtomValue(authState.user);
	const logout = useSetAtom(authState.logout);
	const customAlert = useAlert();

	useEffect(() => {
		const diff = dayjs().endOf('day').diff(now, 'millisecond');

		// 정각에 로그아웃
		const interval = setTimeout(() => {
			customAlert(
				`영업일인 ${now.format('MM/DD')} 에서 하루가 지나 자동으로 로그아웃합니다.`,
				async () => {
					await logout();
					window.location.reload();
				}
			);

			setTimeout(() => window.location.reload(), 5 * 1000);
		}, diff);

		return () => {
			clearTimeout(interval);
		};
	}, [user]);

	return null;
};

MainPageTemplate.useCanLeave = useCanLeave;
