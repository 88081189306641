import { CartLineTypeRules } from '@kinderlabs-pos/shared-data-type';
import { authState, OrderQueryState, PaymentState } from '@kinderlabs-pos/state';
import { List, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { OrderAndPaymentsActions } from './OrderAndPaymentsActions';
import { OrderAndPaymentsExchangeCoupon } from './OrderAndPaymentsExchangeCoupon';
import { OrderAndPaymentsExternalTickets } from './OrderAndPaymentsExternalTickets';
import { OrderAndPaymentsOrder } from './OrderAndPaymentsOrder';
import { OrderAndPaymentsPayment } from './OrderAndPaymentsPayment';
import { OrderAndPaymentPoints } from './OrderAndPaymentsPoints';
import { OrderAndPaymentsSubActions } from './OrderAndPaymentsSubActions';
import { OrderAndPaymentsTicket } from './OrderAndPaymentsTicket';
import { ItemSelectType, OrderAndPaymentsType } from './OrderAndPaymentsType';
import { HwasungPrintActions } from './HwasungPrintActions';

export const selectedOrderAndPaymentItemAtom = atom<ItemSelectType>({ type: 'order' });

export const OrderAndPaymentsContents = ({ orderId }: { orderId: string }) => {
	const isUmmCompany = useAtomValue(authState.enterpriseInfo)?.id === 'ummcompany';
	const { data: orderWithDetail, isFetching } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});
	if (!orderWithDetail) return null;

	const [selected, setSelected] = useAtom(selectedOrderAndPaymentItemAtom);
	useEffect(() => {
		setSelected({ type: 'order' });
	}, [orderId]);

	const paymentTotal = useMemo(
		() => PaymentState.utils.getTotal(orderWithDetail.payments),
		[orderWithDetail]
	);

	const commonProps: OrderAndPaymentsType = {
		selected,
		setSelected,
		orderWithDetail,
		paymentTotal,
		isFetching,
	};

	return (
		<Stack
			height={'100%'}
			sx={{ overflowY: 'auto' }}
			spacing={1}>
			<OrderAndPaymentsOrder {...commonProps} />
			<List
				dense
				disablePadding
				sx={{ flex: 1, width: 240, overflow: 'auto' }}>
				{orderWithDetail.ddizis.length > 0 && <OrderAndPaymentsTicket {...commonProps} />}
				{orderWithDetail.payments.length > 0 && <OrderAndPaymentsPayment {...commonProps} />}
				{orderWithDetail.status !== 'NOT_COMPLETED' && <OrderAndPaymentPoints {...commonProps} />}
				{orderWithDetail.exchangeCoupons.length > 0 && (
					<OrderAndPaymentsExchangeCoupon {...commonProps} />
				)}
				{orderWithDetail.cart.lines.some((cl) => CartLineTypeRules.is외부티켓[cl.type]) && (
					<OrderAndPaymentsExternalTickets {...commonProps} />
				)}
			</List>
			{isUmmCompany && (
				<HwasungPrintActions
					{...commonProps}
					orderWithDetail={orderWithDetail}
				/>
			)}
			<OrderAndPaymentsSubActions {...commonProps} />
			<OrderAndPaymentsActions {...commonProps} />
		</Stack>
	);
};
