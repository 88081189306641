import {
	getNoSessionCartLines,
	useGetSessionCartLinesWithSessionInfo,
} from '@kinderlabs-pos/feature/domain-sessions';
import { ReactHwasungIPC } from '@kinderlabs-pos/ipc/react';
import { OrderDetailType, PaymentMethodTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { sleep } from '@kinderlabs-pos/shared-util';
import { AdminProductInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

export const usePrintHwasung = () => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const getSessionCartLinesWithSessionInfo = useGetSessionCartLinesWithSessionInfo({
		storeId: storeId,
	});
	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId });

	return async ({ order }: { order: OrderDetailType }) => {
		const 통합권있나 = order.cart.lines.some((line) => line.name.includes('통합권'));
		const 과학관있나 = order.cart.lines.some((line) => line.name.includes('과학관'));

		const noSessionLines = getNoSessionCartLines(order.cart.lines ?? []);
		const sessionGroupBy = getSessionCartLinesWithSessionInfo(order.cart.lines);

		const 통합권과학관다있으면두번뽑아야함 = 통합권있나 && 과학관있나;

		const noSessionLines첫번째티켓은통합권먼저 = noSessionLines.filter((cl) => {
			return 통합권과학관다있으면두번뽑아야함 ? cl.name.includes('통합권') : true;
		});

		const 화성Request = {
			TYPE:
				noSessionLines첫번째티켓은통합권먼저.length === 0
					? '유료존'
					: 통합권있나
					? '통합권'
					: '과학관',
			DATE: dayjs(order.created).format('YYYY-MM-DD'),
			// ORDER_NUM: order.id,
			ORDER_NUM:
				order.payments.length > 0
					? PaymentMethodTypeLabel[order.payments[0].method]
					: order.payments[0]?.receipt?.approvalId ?? '',
			TOTAL: `${OrderState.cart.utils.getAggregate(order.cart).totalToPay.toLocaleString()}원`,
			TOTAL_PEOPLE: `${noSessionLines첫번째티켓은통합권먼저.reduce(
				(acc, cl) => acc + cl.quantity,
				0
			)}명`,
			ORDER_LIST:
				noSessionLines첫번째티켓은통합권먼저.length === 0
					? [
							{
								NAME: '유료존',
								QUANTITY: '',
								PRICE: '',
							},
					  ]
					: [
							...(noSessionLines첫번째티켓은통합권먼저.length > 4
								? [...noSessionLines첫번째티켓은통합권먼저].splice(0, 4)
								: noSessionLines첫번째티켓은통합권먼저
							).map((cl) => {
								const product = productMap[Number(cl.productInfoId)];
								return {
									NAME: product.description ?? cl.name,
									QUANTITY: cl.quantity.toString() + '명',
									PRICE: cl.price.toLocaleString() + '원',
								};
							}),
							...(noSessionLines첫번째티켓은통합권먼저.length > 4
								? [
										{
											NAME: '...',
											QUANTITY: '',
											PRICE: '',
										},
								  ]
								: []),
					  ],
			ACTIVITY_LIST: Object.values(sessionGroupBy).map((cartLines) => {
				const cl = cartLines[0];

				return {
					NAME: cl.sessionGroupInfo.name
						.replace('하절기', '')
						.replace('동절기', '')
						.replace('주말', '')
						.replace('평일', ''),
					TIME: `${cl.fullSessionInfo.startTime} ~ ${cl.fullSessionInfo.endTime}`,
					BABY:
						cartLines
							.reduce(
								(acc, cl) => acc + (cl.sessionInfo.sessionBoardIndex === 0 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
					CHILD:
						cartLines
							.reduce(
								(acc, cl) => acc + (cl.sessionInfo.sessionBoardIndex === 1 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
					YOUTH:
						cartLines
							.reduce(
								(acc, cl) => acc + (cl.sessionInfo.sessionBoardIndex === 2 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
					ADULT:
						cartLines
							.reduce(
								(acc, cl) => acc + (cl.sessionInfo.sessionBoardIndex === 3 ? cl.quantity : 0),
								0
							)
							.toString() + '명',
				};
			}),
		};

		await ReactHwasungIPC.invoke티켓출력({
			type: 'printRequest',
			args: 화성Request,
		});

		if (통합권과학관다있으면두번뽑아야함) {
			const 과학관만모다서SessionLines = noSessionLines.filter((cl) => {
				return !cl.name.includes('통합권');
			});

			const 화성Request두번째 = {
				TYPE: '과학관',
				DATE: dayjs(order.created).format('YYYY-MM-DD'),
				// ORDER_NUM: order.id,
				ORDER_NUM:
					order.payments.length > 0
						? PaymentMethodTypeLabel[order.payments[0].method]
						: order.payments[0]?.receipt?.approvalId ?? '',
				TOTAL: `${OrderState.cart.utils.getAggregate(order.cart).totalToPay.toLocaleString()}원`,
				TOTAL_PEOPLE: `${과학관만모다서SessionLines.reduce((acc, cl) => acc + cl.quantity, 0)}명`,
				ORDER_LIST: [
					...(과학관만모다서SessionLines.length > 4
						? [...과학관만모다서SessionLines].splice(0, 4)
						: 과학관만모다서SessionLines
					).map((cl) => {
						const product = productMap[Number(cl.productInfoId)];
						return {
							NAME: product.description ?? cl.name,
							QUANTITY: cl.quantity.toString() + '명',
							PRICE: cl.price.toLocaleString() + '원',
						};
					}),
					...(과학관만모다서SessionLines.length > 4
						? [
								{
									NAME: '...',
									QUANTITY: '',
									PRICE: '',
								},
						  ]
						: []),
				],
				ACTIVITY_LIST: [],
			};

			await sleep(3000);
			for (let i = 0; i < 3; i++) {
				try {
					const result = await ReactHwasungIPC.invoke티켓출력({
						type: 'printRequest',
						args: 화성Request두번째,
					});

					if (result.success) break;
				} catch (e) {
					await sleep(1000);
				}
			}
		}
	};
};

// 성공 실패
