/* 카테고리별 매출은 폐기 */

import {
	CategoryRevenueInfo,
	CategoryRevenueNewInfo,
	매장별CategoryByStoreInfo,
	일자별CategoryByDateInfo,
	카테고리별매출FilterType,
} from '@kinderlabs-pos/shared-data-type';
import { getGroupBy } from '@kinderlabs-pos/shared-util';
import { AdminCategoryInfoState, StatisticsState, authState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ColumnDefsHelper } from './ColumnDefsHelper';

export const NewProductCategoryStatisticsData = {
	use일자별Data: ({
		filter,
		dateType,
	}: {
		filter: 카테고리별매출FilterType;
		dateType: 'DAY' | 'MONTH';
	}) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);

		// 월별 없음ㅋ
		if (filter.dateType === 'TIME') return [];

		const { data } = useQuery({
			...StatisticsState.keys.salesByCategoryForDate({
				params: {
					...filter.calendarOption,
					categorySpec: filter.categorySpec,
					storeIdList: filter.storeIdList || storeIdListForManager,
					posIdList: filter.posIdList,
					...(filter.dateType === 'MONTH'
						? {
								dateType: 'MONTH',
								...filter.calendarOption,
						  }
						: {
								dateType: 'DAY',
								...filter.calendarOption,
						  }),
					isWithTargetCartLine: true,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		return useMemo(() => data?.categoryByDateInfo ?? [], [data]);
	},
	use매장별Data: ({ filter }: { filter: 카테고리별매출FilterType }) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);

		// 월별 없음ㅋ
		if (filter.dateType !== 'DAY') return [];

		const { data } = useQuery({
			...StatisticsState.keys.salesByCategoryForStore({
				params: {
					...filter.calendarOption,
					categorySpec: filter.categorySpec,
					storeIdList: filter.storeIdList || storeIdListForManager,
					posIdList: filter.posIdList,
					dateType: filter.dateType,
					isWithTargetCartLine: true,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		return useMemo(() => data?.categoryByStoreInfo ?? [], [data]);
	},
	use일자별Columns: ({
		x2Data,
		dateType,
	}: {
		x2Data: CategoryRevenueInfo[];
		dateType: 'DAY' | 'MONTH';
	}) => {
		const 대중소분류별데이터Group = useX2DataWith대중소분류(x2Data);

		return useMemo<ColumnDef<일자별CategoryByDateInfo>[]>(() => {
			return [
				{
					accessorKey: 'date',
					header: '매출일자',
					size: 120,
					footer: '총 합',
				},
				...(dateType === 'DAY'
					? ([
							{
								accessorKey: 'dayOfWeek',
								header: '요일',
								size: 60,
								aggregatedCell: undefined,
							},
					  ] as ColumnDef<일자별CategoryByDateInfo>[])
					: []),
				...([commonColumnDef['realRevenueSum']] as ColumnDef<일자별CategoryByDateInfo>[]),
				...common대중소분류<일자별CategoryByDateInfo>(대중소분류별데이터Group),
				// {
				//   id: `${대분류}_${x2.categoryName}_realRevenue`,
				//   accessorFn: (row) => row.categoryRevenueInfo[x2Index].realRevenueSum,
				//   header: `매출`,
				//   size: 140,
				//   ...ColumnDefsHelper.get금액ColumnDef(`${x2.categoryName}_realRevenue`),
				// },
				// ...x2DataWith분류.map((x2, x2Index) => commonX2ColumnDef(x2, x2Index)),
			];
		}, [dateType, 대중소분류별데이터Group]);
	},
	use매장별Columns: ({ x2Data }: { x2Data: CategoryRevenueInfo[] }) => {
		const 대중소분류별데이터Group = useX2DataWith대중소분류(x2Data);
		return useMemo<ColumnDef<매장별CategoryByStoreInfo>[]>(
			() => [
				{
					accessorKey: 'storeName',
					header: '매장',
					size: 120,
					footer: '총 합',
				},
				...([commonColumnDef['realRevenueSum']] as ColumnDef<매장별CategoryByStoreInfo>[]),
				...common대중소분류<매장별CategoryByStoreInfo>(대중소분류별데이터Group),
				// ...x2DataWith분류.map(
				// 	(x2, x2Index) => commonX2ColumnDef(x2, x2Index) as ColumnDef<매장별CategoryByStoreInfo>
				// ),
			],
			[대중소분류별데이터Group]
		);
	},
};

const commonColumnDef: Record<
	string,
	ColumnDef<{
		categoryRevenueInfo: CategoryRevenueInfo[];
		subtotal: CategoryRevenueInfo;
	}>
> = {
	realRevenueSum: {
		id: 'realRevenueSum',
		accessorFn: (row) => row.subtotal.realRevenueSum,
		header: '순 매출',
		size: 150,
		...ColumnDefsHelper.get금액ColumnDef('realRevenueSum'),
	},
	revenueSum: {
		accessorKey: 'revenueSum',
		accessorFn: (row) => row.subtotal.revenueSum,
		header: '순 매출',
		size: 150,
		...ColumnDefsHelper.get금액ColumnDef('revenueSum'),
	},
};

const commonX2ColumnDef = (x2: CategoryRevenueInfo, x2Index: number) =>
	({
		id: `${x2.categoryName}`,
		header: `${x2.categoryName}`,
		accessorFn: (row) => row.categoryRevenueInfo[x2Index].realRevenueSum,
		size: 180,
		...ColumnDefsHelper.get금액ColumnDef(`${x2.categoryName}`),
	} as ColumnDef<일자별CategoryByDateInfo>);

const useX2DataWith대중소분류 = (data: CategoryRevenueNewInfo[]) => {
	const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();
	return useMemo(() => {
		const tempData = data
			.map((row, idx) => {
				return {
					...row,
					idx,
					superCategory: {
						id: categoryTree.BASE[row.categoryId]?.superCategoryInfo.id ?? -1,
						name: categoryTree.BASE[row.categoryId]?.superCategoryInfo.name ?? '기타 중분류',
					},
					topCategory: {
						id: categoryTree.BASE[row.categoryId]?.topCategoryInfo.id ?? -2,
						name: categoryTree.BASE[row.categoryId]?.topCategoryInfo.name ?? '기타 대분류',
					},
				};
			})
			.sort((rowA, rowB) => {
				if (rowA.categoryId === undefined) return 1;
				if (rowB.categoryId === undefined) return -1;
				else return rowA.categoryId < rowB.categoryId ? 1 : -1;
			});

		const 중분류별Group = getGroupBy(tempData, (d) => d.superCategory.id);
		const 중분류별GroupList = [...Object.keys(중분류별Group)].map((중분류) => ({
			대분류: 중분류별Group[Number(중분류)][0].topCategory,
			중분류: 중분류별Group[Number(중분류)][0].superCategory,
			list: 중분류별Group[Number(중분류)],
		}));
		return getGroupBy(중분류별GroupList, (d) => d.대분류.id);
	}, [data, categoryTree]);
};

const common대중소분류 = <T,>(
	대중소분류별데이터Group: ReturnType<typeof useX2DataWith대중소분류>
) => {
	return [...Object.keys(대중소분류별데이터Group)].map(
		(대분류) =>
			({
				id: 대분류,
				header: 대중소분류별데이터Group[Number(대분류)][0].대분류.name,
				columns: 대중소분류별데이터Group[Number(대분류)].map((중분류객체) => ({
					id: `${대분류}_${중분류객체}`,
					header: 중분류객체.중분류.name,
					columns: 중분류객체.list.map((x2) => commonX2ColumnDef(x2, x2.idx)),
				})),
			} as ColumnDef<T>)
	);
};

// 1.
