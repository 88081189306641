import { ServerInfoState } from '@kinderlabs-pos/state';
import {
	Box,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

export interface INetworkPopupProps {}
export const NetworkPopup: React.FC<INetworkPopupProps> = ({}) => {
	return (
		<Paper>
			<Suspense fallback={<CircularProgress />}>
				<_NetworkPopup />
			</Suspense>
		</Paper>
	);
};

const _NetworkPopup = () => {
	const sseConnected = true;
	const serverInfo = useAtomValue(ServerInfoState.info);

	return (
		<List>
			<ListItem>
				<ListItemText>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}>
						<Box
							sx={{
								width: 12,
								height: 12,
								bgcolor: serverInfo && sseConnected ? 'success.light' : 'error.light',
								borderRadius: '50%',
							}}
						/>
						<Typography
							variant='body1'
							color={'text.primary'}>
							{'API 서버'}
						</Typography>
						<Typography
							variant='body2'
							color={'text.secondary'}>
							{`(${serverInfo?.git.commit.id || '-'})`}
						</Typography>
					</Stack>
				</ListItemText>
			</ListItem>
		</List>
	);
};
