import { WarningOutlined } from '@ant-design/icons';
import { OrderAPI } from '@kinderlabs-pos/apis/pos';
import { CartSummary, CartTableWithItems } from '@kinderlabs-pos/feature/pos/cart-table';
import { OrderStatusType } from '@kinderlabs-pos/shared-data-type';
import { OrderState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, useConfirm } from '@kinderlabs-pos/ui-components';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaymentContents } from '../../../modules/payments/PaymentContents';
import { PaymentResult } from '../../../modules/payments/PaymentResult';
import { MainPageTemplate } from '../../MainPageTemplate';
import { WaitingIndicator } from 'apps/pos-main/src/components/PosHeader/WaitingIndicator';

/**
 * POS Main 에서 진입한 경우 orderId === null 이므로 곧바로 MainOrderPaymentPage 으로 진입
 * 결제 이어하기로 진입한 경우 orderId 등 정보를 setup 하고 페이지로 진입
 */
export const OrderPaymentPage = () => {
	const [searchParams] = useSearchParams();
	const [orderReady, setOrderReady] = useState(false);

	const orderId = searchParams.get('orderId');
	const mode = searchParams.get('mode') as 'order' | 'jungsan' | 'order_continue';
	const status = searchParams.get('status') as OrderStatusType | undefined;

	const dispatchOrder = useSetAtom(OrderState.value);
	useEffect(() => {
		if (orderId === null) return setOrderReady(true);
		OrderAPI.getDetail({ orderId }).then((order) => {
			if (mode === 'order_continue') {
				dispatchOrder({ type: 'LOAD', order: order });
			} else if (mode === 'jungsan') {
				dispatchOrder({ type: 'SET_GUEST_USERS_INFO', guestMemberInfo: order.guestMemberInfo });
			}

			setOrderReady(true);
		});
	}, [orderId]);

	return orderReady ? (
		<MainOrderPaymentPage
			mode={mode}
			originOrderStatus={status}
		/>
	) : (
		<CircularProgress />
	);
};

const MainOrderPaymentPage = ({
	mode,
	originOrderStatus,
}: {
	mode: 'order' | 'jungsan' | 'order_continue';
	originOrderStatus?: OrderStatusType;
}) => {
	const order = useAtomValue(OrderState.value);
	/**
	 * 결제 완료에 대한 처리
	 */
	// const { handleFinished, finished } = useHandleOrderPaymentFinished({
	// 	onlyPayment: originOrderStatus === 'PARTIALLY_CANCELED',
	// });

	/**
	 * 페이지를 벗어나는 경우에 대한 처리
	 */
	useHandleExitPage({ mode, orderId: order.id });
	const finished = order.status === 'COMPLETED';

	return (
		<Stack
			flex={1}
			direction={'row'}
			spacing={1}
			height={'100%'}
			sx={{ overflow: 'auto' }}>
			<CardStack
				flex={2}
				sx={{ overflow: 'auto' }}>
				<Box
					flex={1}
					sx={{ overflow: 'auto' }}>
					<CartTableWithItems />
				</Box>
				<CartSummary />
			</CardStack>
			<CardStack
				variant={'nocolors'}
				flex={3}>
				{!finished && (
					<Suspense fallback={<FullSizeCircularProgress />}>
						<PaymentContents
							mode={mode === 'jungsan' ? 'JUNGSAN' : 'ORDER'}
							// handleFinished={handleFinished}
						/>
					</Suspense>
				)}
				{finished && (
					<Suspense fallback={<FullSizeCircularProgress />}>
						<PaymentResult
							mode={
								mode === 'jungsan'
									? 'jungsan'
									: originOrderStatus === 'PARTIALLY_CANCELED'
									? 'order_not_print'
									: 'order_print'
							}
						/>
					</Suspense>
				)}
			</CardStack>
		</Stack>
	);
};

/**
 * @param options: onlyPayment 는 주문 완성 없이 결제만 하기 위한 옵션
 */
// const useHandleOrderPaymentFinished = (options: { onlyPayment: boolean }) => {
// 	const [finished, setFinished] = useState(false);

// 	const completeOrder = useSetAtom(OrderState.actions.completeOrder);
// 	const orderCart = useAtomValue(OrderState.cart.selector);

// 	return {
// 		finished,
// 		handleFinished: async () => {
// 			try {
// 				if (!options.onlyPayment) {
// 					await completeOrder(orderCart);
// 				}
// 				setFinished(true);
// 			} catch {
// 				console.error(
// 					'이 때 어떻게 해야하지? 반복해서 3번 리퀘스트 하고 안 되면 오프라인으로 떨어뜨려야하나'
// 				);
// 				console.error('React Query 를 도입해서 최대한 깔끔히 만들어보자.');
// 			}
// 		},
// 	};
// };
/**
 * 주문 + 주문 이어하기
 * 1) 주문 타입, 주문이 아직 안 만들어졌음(결제 내역 자체가 없음) : 그냥 나가면 됨 canNotLeave = false
 * 2) 주문 타입 + 주문이 이미 만들어졌고, 유효한(환불 안 된) 결제 내역이 있음 : 주문 임시저장
 * 3) 주문 타입 + 주문이 이미 만들어졌고, 유효한(환불 안 된) 결제 내역이 없음 (모두 환불되었음) : 메인 화면으로 빠져나갈 수 있게 하기
 * - 응대 종료시 전부 초기화 됨
 *
 * 1) 정산 타입, 주문이 아직 안 만들어졌음(결제 내역 자체가 없음) : 그냥 나가면 됨 canNotLeave = false
 * 2) 정산 타입, 주문이 이미 만들어졌고, 유효한(환불 안 된) 결제 내역이 있음 : 나가기 불가능. 먼저 환불해야함
 * 3) 정산 타입, 주문이 이미 만들어졌고, 유효한(환불 안 된) 결제 내역이 없음 (모두 환불되었음) : 주문 취소를 하고 장바구니 정보는 유지할 것.
 * - 응대 종료시 전부 초기화 됨
 */
const useHandleExitPage = ({
	mode,
	orderId,
}: {
	mode: 'order' | 'jungsan' | 'order_continue';
	orderId?: string;
}) => {
	const confirm = useConfirm();

	const { totalPaid, remainingPay } = useAtomValue(OrderState.aggregate.payments);
	const canNotLeave = !!orderId && remainingPay !== 0;

	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const discounts = cartLines.filter((cl) => OrderState.cart.lines.boardUtils.isDiscountItem(cl));
	const hasDiscount = discounts.length > 0;

	const setCalled지류티켓 = useSetAtom(WaitingIndicator.called지류티켓Atom);
	const 임시저장AndClear = OrderState.actions.usePostponeAndClear({
		orderType: mode === 'jungsan' ? 'JUNGSAN' : 'ORDER',
	});

	MainPageTemplate.useCanLeave(
		useCallback(async () => {
			if (!canNotLeave) return true;
			return new Promise((resolve) =>
				confirm(
					<Stack spacing={1}>
						<Typography
							color={'error'}
							variant={'h3'}>
							<WarningOutlined />
							{' 이미 결제된 항목이 있습니다. 결제를 중단하시겠습니까?'}
						</Typography>
						<Typography variant={'subtitle1'}>
							{'∙ 결제를 중단하면 "주문 내역"에서 해당 주문에 대한 결제를 이어서 할 수 있습니다'}
						</Typography>
					</Stack>,
					async () => {
						await 임시저장AndClear.mutateAsync();
						setCalled지류티켓(undefined);
						resolve(true);
					},
					async () => {
						resolve(false);
					}
				)
			);
		}, [canNotLeave, hasDiscount])
	);
};
