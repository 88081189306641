import {
	CartActionBoard,
	WithCustomProductDialog,
	WithPosCouponDialog,
} from '@kinderlabs-pos/feature/pos/cart-table';
import { 수표검증가능 } from '@kinderlabs-pos/shared-data-type';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useAtomValue, useSetAtom } from 'jotai';
import { CheckCheckDialog } from '../../pages/OrderPage/CheckCheckDialog';
import { KyulsanOrderDialog } from '../../pages/OrderPage/KyulsanOrderDialog';
import { PriceChangeInCartDialog } from '../../pages/OrderPage/PriceChangeInCartDialog';
import { WaitingIndicator } from '../../components/PosHeader/WaitingIndicator';

export const EtcCartMenuContent = ({ onComplete }: { onComplete: () => void }) => {
	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);

	const isEmpty = OrderState.cart.utils.isEmptyCart(useAtomValue(OrderState.cart.selector));
	const 결산불가능 = OrderState.cart.utils.결산불가능(useAtomValue(OrderState.cart.selector));

	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const setCalled지류티켓 = useSetAtom(WaitingIndicator.called지류티켓Atom);
	const 임시저장AndClear = OrderState.actions.usePostponeAndClear({
		orderType: 'ORDER',
	});

	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();

	const customConfirm = useConfirm();
	const handleClick임시저장 = () => {
		customConfirm(
			<Typography
				variant={'h3'}
				color='text.primary'>
				{'주문을 저장하고 초기화하시겠습니까?'}
				<br />
				<br />
				<Typography
					component={'span'}
					variant={'subtitle1'}
					color='text.secondary'>
					{'저장된 주문은 주문 페이지 혹은 결제 페이지에서 재결제 하실 수 있습니다.'}
				</Typography>
			</Typography>,
			() => 임시저장AndClear.mutateAsync()
		);
	};

	return (
		<Grid2
			height={'100%'}
			container
			spacing={1}>
			<Grid2 xs={6}>
				<WithCustomProductDialog closeETCDialog={onComplete}>
					{(onClick) => (
						<Button
							fullWidth
							size={'large'}
							variant={'outlined'}
							onClick={onClick}>
							<Typography variant='subtitle1'>{'임의 상품'}</Typography>
						</Button>
					)}
				</WithCustomProductDialog>
			</Grid2>
			<Grid2 xs={6}>
				<Button
					fullWidth
					size={'large'}
					disabled={!guestMemberInfo && isEmpty}
					variant={'outlined'}
					onClick={() => {
						handleClick임시저장();
						setCalled지류티켓(undefined);
						onComplete();
					}}>
					<Typography variant='subtitle1'>{'임시 저장'}</Typography>
				</Button>
			</Grid2>
			<Grid2 xs={6}>
				<WithPosCouponDialog closeETCDialog={onComplete}>
					{(onClick) => (
						<Button
							fullWidth
							size={'large'}
							variant={'outlined'}
							onClick={onClick}>
							<Typography variant='subtitle1'>{'POS 쿠폰 사용'}</Typography>
						</Button>
					)}
				</WithPosCouponDialog>
			</Grid2>
			<Grid2 xs={6}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<KyulsanOrderDialog
							open={open}
							closeDialog={() => {
								onComplete();
								closeDialog();
							}}
						/>
					)}>
					{(openDialog) => (
						<Button
							fullWidth
							size={'large'}
							disabled={isEmpty || 결산불가능}
							variant={'outlined'}
							onClick={openDialog}>
							<Typography variant='subtitle1'>{'결산 주문'}</Typography>
						</Button>
					)}
				</WithDialog>
			</Grid2>
			<Grid2 xs={6}>
				<WithDialog
					dialog={(open, closeDialog) =>
						selectedCartLine && (
							<PriceChangeInCartDialog
								open={open}
								closeDialog={() => {
									onComplete();
									closeDialog();
								}}
								selectedCartLine={selectedCartLine}
							/>
						)
					}>
					{(openDialog) => (
						<Button
							fullWidth
							size={'large'}
							disabled={!selectedCartLine}
							variant={'outlined'}
							onClick={openDialog}>
							<Typography variant='subtitle1'>{'가격 변경'}</Typography>
						</Button>
					)}
				</WithDialog>
			</Grid2>
			{수표검증가능[vanCompany] && (
				<Grid2 xs={6}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CheckCheckDialog
								open={open}
								closeDialog={() => {
									onComplete();
									closeDialog();
								}}
							/>
						)}>
						{(openDialog) => (
							<Button
								fullWidth
								size={'large'}
								variant={'outlined'}
								onClick={openDialog}>
								<Typography variant='subtitle1'>{'수표 검증'}</Typography>
							</Button>
						)}
					</WithDialog>
				</Grid2>
			)}
		</Grid2>
	);
};
