import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import MergeIcon from '@mui/icons-material/Merge';
import { IconButtonProps, Typography } from '@mui/material';

export interface IMergeButtonProps {
	onClick: IconButtonProps['onClick'];
	disabled?: boolean;
}
export const MergeButton: React.FC<IMergeButtonProps> = ({ onClick, disabled }) => {
	return (
		<IconTextButton
			iconSize='large'
			disabled={disabled}
			onClick={onClick}
			icon={<MergeIcon fontSize='small' />}
			label={<Typography>{'병합'}</Typography>}
		/>
	);
};
